import { Box } from '@mui/material'
import React from 'react'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../components/utils'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

const OKC = () => {
  const formUrl = `https://docs.google.com/forms/d/e/1FAIpQLScfq8uQMIUBgECHixHPWY9SoOi1SjizNZ2l-LyL7GCO6TLfag/viewform`

  useRecordPageDuration()
  useScrollDepthTracker()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <iframe
        src={formUrl}
        width="100%"
        height="100%"
        title="Google Form - OKC"
      >
        Loading…
      </iframe>
    </Box>
  )
}

export default OKC
